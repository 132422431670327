<template>
  <section class="">
    <div class="pa-3">
      <h1 class="page-title">
        Устройство: {{ formData.name }}, Расположение: {{ formData.location }}
      </h1>
    </div>

    <v-container fluid>
      <v-tabs
        hide-slider
        height="32"
        v-model="tab"
        class="base-tabs"
        active-class="base-tabs__active"
      >
        <div v-for="item in tabs" :key="item.tab">
          <v-tab class="text-capitalize">
            {{ item.tab }}
          </v-tab>
        </div>
      </v-tabs>

      <!-- <v-card color="white" flat height="100%" style="border-radius: 5px" tile> -->
      <v-tabs-items v-model="tab">
        <v-tab-item
          v-for="item in tabs"
          :key="item.tab"
          class="border--primary"
        >
          <component
            v-if="formData.id || true"
            :formData="formData"
            :is="item.componentName"
          />
        </v-tab-item>
      </v-tabs-items>
    </v-container>

    <!-- </v-card> -->
  </section>
</template>
<script>
import SettingsDevices from "@/views/DevicesTabs/SettingsDevices";

export default {
  name: "DevicesDetails",
  components: {
    SettingsDevices,
  },
  data() {
    return {
      formData: {},
      tab: 0,
      tabs: [
        {
          tab: "Настройки",
          content: "Настройки",
          name: "SettingsDevices",
          componentName: "SettingsDevices",
        },
      ],
    };
  },
  mounted() {
    this.$store
      .dispatch("fetchDevice", { id: this.$route.params.id })
      .then(() => {
        this.formData = this.$store.getters.getCurrentDevice.data[0];
      });
  },
};
</script>
