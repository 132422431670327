<template>
  <!-- widht 460px -->
  <div class="setting-block">
    <v-row class="my-3">
      <v-col cols="4">
        <div class="text-left">
          Серийный номер
        </div>
      </v-col>
      <v-col cols="8">
        <div id="textFieldInputDevice">
          <v-text-field
            dense
            hide-details
            outlined
            class="base-text-field"
            v-model="currentDevice.serial"
          />
        </div>
      </v-col>
      <v-col cols="4">
        <div class="text-left">
          Тип устройства
        </div>
      </v-col>
      <v-col cols="8">
        <div id="textFieldInputDevice">
          <v-autocomplete
            dense
            hide-details
            outlined
            class="base-auto-complete"
            disabled
            id="autoCompleteInput"
            v-model="currentDevice.type"
            :items="typeDevice"
            :item-text="typeDevice.text"
            item-value="value"
            no-data-text="Нет данных"
          />
        </div>
      </v-col>

      <v-col cols="4">
        <div class="text-left">
          Наименование
        </div>
      </v-col>
      <v-col cols="8">
        <div id="textFieldInputDevice">
          <v-text-field
            dense
            hide-details
            outlined
            class="base-text-field"
            v-model="currentDevice.name"
          />
        </div>
      </v-col>

      <v-col cols="4">
        <div class="text-left">
          MAC
        </div>
      </v-col>
      <v-col cols="8">
        <div id="textFieldInputDevice">
          <v-text-field
            dense
            hide-details
            outlined
            class="base-text-field"
            v-model="currentDevice.mac"
          />
        </div>
      </v-col>
      <v-col cols="4">
        <div class="text-left">
          Профиль
        </div>
      </v-col>
      <v-col cols="8">
        <div id="textFieldInputDevice">
          <v-autocomplete
            dense
            hide-details
            outlined
            class="base-auto-complete"
            id="autoCompleteInput"
            v-model="currentDevice.profile_id"
            :items="profilesItems"
            item-text="name"
            item-value="id"
            no-data-text="Нет данных"
            @change="profileChanged"
          />
        </div>
      </v-col>
      <v-col cols="4" v-if="currentDevice.type === 'tablet'">
        <div class="text-left">
          Отменять при отсутствии автивности
        </div>
      </v-col>
      <v-col cols="8" v-if="currentDevice.type === 'tablet'">
        <div id="textFieldInputDevice">
          <v-text-field
            dense
            hide-details
            outlined
            readonly
            class="base-text-field"
            v-model="currentDevice.profile.inactivity_cancel_timeout"
          >
            <template v-slot:append>мин</template>
          </v-text-field>
        </div>
      </v-col>

      <v-col cols="4" v-if="currentDevice.type === 'tablet'">
        <div class="text-left">
          Максимальное время встречи
        </div>
      </v-col>
      <v-col cols="8" v-if="currentDevice.type === 'tablet'">
        <div id="textFieldInputDevice">
          <v-text-field
            dense
            hide-details
            outlined
            readonly
            class="base-text-field"
            v-model="currentDevice.profile.max_reservation_time"
          >
            <template v-slot:append>мин</template>
          </v-text-field>
        </div>
      </v-col>

      <v-col cols="4">
        <div class="text-left">
          Прошивка
        </div>
      </v-col>
      <v-col cols="8">
        <div id="textFieldInputDevice">
          <v-text-field
            dense
            hide-details
            outlined
            class="base-text-field"
            v-model="currentDevice.settings.firmware"
          >
            <template v-slot:append-outer>
              <div
                class=""
                style="
                    width: 30px;
                    height: 27px;
                    cursor: pointer;
                    background: #eeac57;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  "
                @click="handleFileImport"
              >
                <input
                  ref="uploader"
                  accept="image/*"
                  class="d-none"
                  type="file"
                />
                <span>...</span>
              </div>
            </template>
          </v-text-field>
        </div>
      </v-col>

      <v-col cols="12">
        <div class="text-left">
          <v-btn
            tile
            class="text-capitalize white--text"
            color="green"
            elevation="0"
            @click="submitForm"
          >
            Сохранить
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <!-- <InfoDialog
      :info-dialog-text="infoDialogText"
      :is-info-dialog="isInfoDialog"
      @accept="closeWarn"
    /> -->
    <v-snackbar v-model="isInfoDialog" color="white" top>
      <span class="black--text">
        {{ infoDialogText }}
      </span>
    </v-snackbar>
  </div>
</template>

<script>
// import InfoDialog from "@/components/Dialogs/InfoDialog";

export default {
  name: "Settings",
  components: {},
  data() {
    return {
      currentDevice: {
        settings: {},
      },
      isInfoDialog: false,
      infoDialogText: "",

      typeDevice: [
        {
          text: "Планшет",
          value: "tablet",
        },
        {
          text: "Датчик",
          value: "sensor",
        },
      ],
      formdata: null,
    };
  },
  methods: {
    submitForm() {
      //Update Device
      let fd = new FormData();
      fd.append("data", JSON.stringify({ ...this.currentDevice }));
      const payload = { ...this.currentDevice };
      this.$store
        .dispatch("updateDevice", {
          id: this.currentDevice.id,
          payload: payload,
        })
        .then(() => {
          this.infoDialogText = "Успешно изменено";
          this.isInfoDialog = true;
        })
        .catch((err) => {
          this.infoDialogText = `Ошибка: ${err.response.data.code} ${err.response.data.error}`;
          this.isInfoDialog = true;
        });
    },
    initialize() {
      this.currentDevice = { ...this.formData };
      console.log("current device ", this.currentDevice);
      if (!this.formData.settings) this.currentDevice.settings = {};
    },
    handleFileImport() {
      this.$refs.uploader.click();
    },
    profileChanged() {},
    closeWarn() {
      this.isInfoDialog = false;
    },
  },
  mounted() {
    this.$store
      .dispatch("fetchDevice", { id: this.$route.params.id })
      .then(() => {
        this.formData = this.$store.getters.getCurrentDevice.data[0];
        this.initialize();
        this.$store.dispatch("fetchProfiles");
      })
      .catch(() => {
        this.formData = null;
      });
  },
  computed: {
    profilesItems() {
      return this.$store.getters.getProfilesDataArray.filter(
        (item) => item.device_type == this.currentDevice.type.toUpperCase()
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.setting-block {
  max-width: 460px;
}
</style>
